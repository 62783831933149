import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class Register extends React.Component {

    state = {
        cocNumber: '',
        companyName: '',
        address: '',
        addressNum: '',
        customerOf: '',
        customerRel: '',
        postalCode: '',
        city: '',
        name: '',
        number: '',
        email: '',
        password: '',
        passwordRepeat: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.data.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/register');
                } else {
                    this.props.navigate('/account');
                }

            }
        });
    }
    
    customerOfSelect(e) {
        var customRelInput = document.querySelector('.form-group.relatienummer');

        this.setState({customerOf: e.target.value}, () => {
            if (this.state.customerOf !== "vonnisbank") {
                customRelInput.classList.remove('hidden');
                customRelInput.setAttribute('required', true);
            } else {
                customRelInput.classList.add('hidden');
                customRelInput.removeAttribute('required');
            }
        });
    }

    registerUser(e) {
		e.preventDefault();

        const strengthpass = document.querySelector('.password-strength');
        const samepass = document.querySelector('.password-same');
        const loginerror = document.querySelector('.login-error');
        const companyerror = document.querySelector('.company-error');
        const alert = document.querySelector('.alert-error');

        const btnRegister = document.querySelector('.button-register');

        const btnAccess = document.querySelector('.button-access');
        const btnReset = document.querySelector('.button-reset');

        strengthpass.classList.add('hidden');
        samepass.classList.add('hidden');
        loginerror.classList.add('hidden');
        companyerror.classList.add('hidden');
        alert.classList.add('hidden');

        btnRegister.classList.remove('hidden');

        btnAccess.classList.add('hidden');
        btnReset.classList.add('hidden');
        
        
        if (this.state.password === this.state.passwordRepeat) {

            const baseUrl = process.env.REACT_APP_API_BASEURL;

            const newPassword = this.state.password;
            const regularExpression = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})"); // eslint-disable-line

            if(!regularExpression.test(newPassword)) {
                alert.classList.remove('hidden');
                strengthpass.classList.remove('hidden');
            } else {
                var fullAddress = this.state.address + ' ' + this.state.addressNum;

                axios.post(`${baseUrl}/api/register/`, {
                    cocNumber: this.state.cocNumber,
                    companyName: this.state.companyName,
                    address: fullAddress,
                    customerOf: this.state.customerOf,
                    customerRel: this.state.customerRel,
                    postalCode: this.state.postalCode,
                    city: this.state.city,
                    name: this.state.name,
                    number: this.state.number,
                    email: this.state.email,
                    password: this.state.password,
                },
                {
                    withCredentials: true 
                })
                .then((response) => {
                    this.props.navigate('/welkom-bij-vonnisbank');
                })
                .catch((error) => {
                    //show account exist error
                    if (error.response.data.error === 'Duplicate account') {
                        alert.classList.remove('hidden');
                        loginerror.classList.remove('hidden');
                    }

                    //show company exist message
                    if (error.response.data.error === 'Duplicate company') {
                        alert.classList.remove('hidden');
                        companyerror.classList.remove('hidden');

                        //Disable fields and show buttons
                        var fields = document.querySelectorAll('.form-control');
                        for (var i = 0; i < fields.length; i++) {
                            fields[i].setAttribute('disabled', '');
                        }

                        btnRegister.classList.add('hidden');

                        btnAccess.classList.remove('hidden');
                        btnReset.classList.remove('hidden');
                    }
                });
            }

        } else {
            //Wachtwoord herhalen niet hetzelfde
            alert.classList.remove('hidden');
            samepass.classList.remove('hidden');
        }

	}

    requestAccess(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;
        
        axios.post(`${baseUrl}/api/register/company`, {
            cocNumber: this.state.cocNumber,
            companyName: this.state.companyName,
            address: this.state.address,
            postalCode: this.state.postalCode,
            city: this.state.city,
            name: this.state.name,
            number: this.state.number,
            email: this.state.email,
            password: this.state.password,
        },
        { 
            withCredentials: true 
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    clearFields(e) {
        e.preventDefault();

        const alert = document.querySelector('.alert-error');
        const companyerror = document.querySelector('.company-error');

        const btnRegister = document.querySelector('.button-register');

        const btnAccess = document.querySelector('.button-access');
        const btnReset = document.querySelector('.button-reset');

        this.setState({
            cocNumber: '',
            companyName: '',
            address: '',
            postalCode: '',
            city: '',
            name: '',
            number: '',
            email: '',
            password: '',
            passwordRepeat: '',
        });

        var fields = document.querySelectorAll('.form-control');
        for (var i = 0; i < fields.length; i++) {
            fields[i].removeAttribute('disabled');
        }

        alert.classList.add('hidden');
        companyerror.classList.add('hidden');

        btnRegister.classList.remove('hidden');

        btnAccess.classList.add('hidden');
        btnReset.classList.add('hidden');
    }

    kvkLookup(e) {
        if (e.type === 'keydown' && e.code !== 'Enter') {
            return;
        }

        if (this.state.cocNumber.length !== 8) {
            return;
        }

        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/kvklookup/${this.state.cocNumber}`)
        .then((response) => {
            var kvkInfoField = document.querySelector('#kvkLookupResult');

            kvkInfoField.innerHTML = "Bezig met zoeken..."

            setTimeout(() => {
                var companyInfo = JSON.parse(response.data.kvk);

                if (companyInfo.resultaten) {
                    kvkInfoField.innerHTML = `
                        <p><b>${companyInfo.resultaten[0].naam}</b></p>
                        <p>${companyInfo.resultaten[0].adres.binnenlandsAdres.plaats}</p>
                        <p>${companyInfo.resultaten[0].adres.binnenlandsAdres.straatnaam}</p>
                    `

                    this.setState({companyName: companyInfo.resultaten[0].naam}, () => {
                        var fieldCompany = document.querySelector('.form-control.companyName');
                        fieldCompany.setAttribute('disabled', true);
                    });

                    this.setState({address: companyInfo.resultaten[0].adres.binnenlandsAdres.straatnaam}, () => {
                        var fieldAddress = document.querySelector('.form-control.address');
                        fieldAddress.setAttribute('disabled', true);
                    });

                    this.setState({city: companyInfo.resultaten[0].adres.binnenlandsAdres.plaats}, () => {
                        var fieldCity = document.querySelector('.form-control.city');
                        fieldCity.setAttribute('disabled', true);
                    });
                } else {
                    kvkInfoField.innerHTML = `
                        <p><b>Er zijn geen gegevens gevonden</b></p>
                        <p>Indien u er zeker van bent dat u de juiste gegevens heeft kunt u deze handmatig invullen</p>
                    `
                }
            }, 1000);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="Main">
             <Header />
             <div className="bg-pattern front">
                <div className="container mx-auto main-container">
                    <div className="page-intro">
                        <img src={Logovisual} className="logo-visual" alt="logo visual" />
                        <h1>Registreren</h1>
                        <div className="intro">
                            <p>
                                Maak een account aan met uw KvK-nummer.<br />
                                Bij een onbekend nummer wordt U gevraagd uw gegevens handmatig in te geven.
                            </p>            
                        </div>
                    </div>
                    <div className="form-wrapper">

                        <div className="alert alert-error hidden" role="alert">
                            <ul>
                                <li className="login-error hidden">Er is een fout opgetreden, zorg dat alle velden juist zijn ingevuld en er geen account bestaat met hetzelfde e-mailadres.</li>
                                <li className="company-error hidden">Dit bedrijf is al bekend bij Vonnisbank. Als u zich wilt aanmelden als collega kan dat via de onderstaande knop.<br /><br /><b>Indien er nog vragen zijn neem dan contact op met onze klantenservice.</b></li>
                                <li className="password-strength hidden">Uw wachtwoord moet voldoen aan de volgende voorwaarden: minimaal 1 kleine letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken en minimaal 7 tekens lang.</li>
                                <li className="password-same hidden">Wachtwoorden komen niet overeen.</li>
                            </ul>
                        </div>

                        <form onSubmit={this.registerUser.bind(this)}>
                            <div className="form-group kvk">
                                <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} onKeyDown={this.kvkLookup.bind(this)} type="text" placeholder="KvK-nummer" className="form-control" required />
                                <div className="input-group-btn">
                                    <button className="btn btn-small btn-default" type="button" onClick={this.kvkLookup.bind(this)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="alert alert-info" id="kvkLookupResult">Vul een KvK-nummer in om te zoeken</div>
                            </div>
                            <div className="form-group">
                                <input value={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} type="text" placeholder="Bedrijfsnaam" className="form-control companyName" required />
                            </div>
                            <div className='grid sm:grid-cols-12 sm:gap-2'>
                                <div className="form-group sm:col-span-8 col-span-12">
                                    <input value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} type="text" placeholder="Adres" className="form-control address" required />
                                </div>
                                <div className="form-group sm:col-span-4 col-span-12">
                                    <input value={this.state.addressNum} onChange={(e) => this.setState({addressNum: e.target.value})} type="text" placeholder="Nummer" className="form-control addressNum" required />
                                </div>
                            </div>
                            <div className='grid sm:grid-cols-12 sm:gap-2'>
                                <div className="form-group sm:col-span-5 col-span-12">
                                    <input value={this.state.postalCode} onChange={(e) => this.setState({postalCode: e.target.value})} type="text" placeholder="Postcode" className="form-control" required />
                                </div>
                                <div className="form-group sm:col-span-7 col-span-12">
                                    <input value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} type="text" placeholder="Plaats" className="form-control city"  />
                                </div>
                            </div>
                            <div className="form-group">
                                <input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} type="text" placeholder="Naam contactpersoon" className="form-control" required />
                            </div>
                            <div className="form-group">
                                <PhoneInput
                                    country={'nl'}
                                    regions={'europe'}
                                    value={this.state.number}
                                    onChange={number => this.setState({ number })}
                                    inputProps={{
                                        required: true,
                                      }}
                                />
                            </div>
                            <div className="form-group">
                                <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="email" placeholder="E-mailadres" className="form-control" required />
                            </div>
                            <div className="form-group">
                                <select value={this.state.customerOf} onChange={this.customerOfSelect.bind(this)} className="form-control" required>
                                    <option value="vonnisbank">- Klantrelatie -</option>
                                    <option value="flynth">Flynth</option>
                                    <option value="straetus">Straetus</option>
                                    <option value="vvcm">VVCM</option>
                                    <option value="mooredrv">Moore DRV</option>
                                </select>
                            </div>
                            <div className="form-group relatienummer hidden">
                                <input value={this.state.customerRel} onChange={(e) => this.setState({customerRel: e.target.value})} type="text" placeholder="Relatienummer" className="form-control" />
                            </div>
                            <div className="form-hint">
                                Het wachtwoord moet voldoen aan:<br /> 
                                minimaal 1 kleine letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken en tenminste 7 tekens lang
                            </div>
                            <div className='grid sm:grid-cols-2 sm:gap-2'>
                                <div className="form-group">
                                    <input value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} type="password" placeholder="Wachtwoord" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.passwordRepeat} onChange={(e) => this.setState({passwordRepeat: e.target.value})} type="password" placeholder="Herhaal wachtwoord" className="form-control" required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" value="1" required /> Ik ga akkoord met de <a href="/algemene-voorwaarden" target="_blank">algemene voorwaarden</a> van vonnisbank.nl
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="submit" className="button button-primary button-block button-big button-register" value="Gratis account aanmaken" />
                            </div>
                            <div className="form-group">
                                <button type="button" onClick={this.requestAccess.bind(this)} className="button button-primary button-block button-big button-access hidden">Toegang aanvragen</button>
                            </div>
                            <div className="form-group">
                                <button type="button" onClick={this.clearFields.bind(this)} className="button ghost button-block button-big button-reset hidden">Velden leegmaken</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Register navigate={navigate} />
}

export default WithNavigate;